import { useStaticQuery, graphql } from "gatsby";
import React, { useState, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import respond from "../styles/abstracts/mediaqueries";
import ReCAPTCHA from "react-google-recaptcha";

import { handleContactFormSubmit } from "../utils/handleFormSubmits";

import AppContext from "../context/AppContext";

import Button from "../components/Button";

const StyledContactsForm = styled.div`
  margin: 5rem 0;

  .form-input {
    border: none;
    outline: none;
    width: 70%;
    border-bottom: 1px solid #cfcfcf;
    padding: 1rem 0;

    &:placeholder-shown + label {
      opacity: 0;
      visibility: hidden;
      transform: translateX(7px) translateY(-32px);
    }

    ${respond(
      "tab-land",
      css`
        width: 80%;
      `
    )}
    ${respond(
      "tab-port",
      css`
        width: 80%;
      `
    )}
    ${respond(
      "phone-port",
      css`
        width: 100%;
      `
    )}

    &--textarea {
      height: 10rem;
    }

    &::placeholder {
      color: #cfcfcf;
    }
  }

  .form-control {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    &--checkbox {
      display: flex;
      align-items: center;
      gap: 3rem;
      width: 70%;
      flex-direction: row;
      margin-top: 0;
      margin-bottom: 4rem !important;

      ${respond(
        "tab-port",
        css`
          width: 80%;
        `
      )}

      .checkbox {
        transform: scale(1.5);

        ${respond(
          "phone-port",
          css`
            width: 12rem;
          `
        )}
      }
    }
  }

  .input-label {
    color: #cfcfcf;
    font-size: 1.6rem;
    transition: all 0.3s;
    transform: translateX(2px) translateY(-6.5rem);

    &--message {
      transform: translateX(2px) translateY(-12rem);
    }
  }

  .btn--contacts {
    width: 70%;

    ${respond(
      "tab-land",
      css`
        width: 80%;
      `
    )}
    ${respond(
      "phone-port",
      css`
        width: 100%;
      `
    )}
  }
`;

const ContactsForm = () => {
  const {
    strapiNicoleWebsite: {
      homePage: {
        subscriptionForm: { privacyPolicy },
      },
    },
  } = useStaticQuery(query);

  const recaptchaRef = useRef();
  const { setAlertState, submitting, setSubmitting } = useContext(AppContext);

  const [formData, setFormData] = useState({
    first_name: "",
    email: "",
    phone_number: "",
    message: "",
    privacy_accepted: false,
  });

  // React.useEffect(() => {
  //   console.log(formData);
  // }, [formData]);

  function handleChange(e) {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  }

  return (
    <StyledContactsForm>
      <ReCAPTCHA
        ref={recaptchaRef}
        style={{ zIndex: 600 }}
        size="invisible"
        sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
      />
      <form onSubmit={(e) => handleContactFormSubmit(e, formData, setAlertState, recaptchaRef, setSubmitting)}>
        <div className="form-control">
          <input
            className="form-input"
            type="text"
            name="first_name"
            id="contacts-name"
            placeholder="Full name"
            value={formData.first_name}
            onChange={handleChange}
          />
          <label htmlFor="contacts-name" className="input-label">
            Name
          </label>
        </div>
        <div className="form-control">
          <input
            className="form-input"
            type="email"
            name="email"
            id="contacts-email"
            placeholder="Email address*"
            value={formData.email}
            onChange={handleChange}
          />
          <label htmlFor="contacts-name" className="input-label">
            Email
          </label>
        </div>
        <div className="form-control">
          <input
            className="form-input"
            type="tel"
            name="phone_number"
            id="contacts-phone"
            placeholder="Phone number"
            value={formData.phone_number}
            onChange={handleChange}
          />
          <label htmlFor="contacts-name" className="input-label">
            Phone Number
          </label>
        </div>
        <div className="form-control form-control--message">
          <textarea
            className="form-input form-input--textarea"
            name="message"
            id="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
          />
          <label htmlFor="contacts-name" className="input-label input-label--message">
            Message
          </label>
        </div>
        <div className="form-control form-control--checkbox">
          <input
            type="checkbox"
            className="checkbox"
            name="privacy_accepted"
            id="contactsPrivacy"
            checked={formData.privacy_accepted}
            onChange={handleChange}
          />
          {/* eslint-disable-next-line */}
          <label
            htmlFor="contactsPrivacy"
            className="label"
            dangerouslySetInnerHTML={{ __html: privacyPolicy }}
          ></label>
        </div>
        <div className="form-control">
          <Button className="btn--contacts" type="submit">
            {submitting ? "Submitting ..." : "Submit"}
          </Button>
        </div>
      </form>
    </StyledContactsForm>
  );
};

export const query = graphql`
  {
    strapiNicoleWebsite {
      homePage {
        subscriptionForm {
          privacyPolicy
        }
      }
    }
  }
`;

export default ContactsForm;
