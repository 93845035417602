import React from "react";
import styled, { css } from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import respond from "../../styles/abstracts/mediaqueries";

import ContactsForm from "../../components/ContactsForm";

const StyledContactsBody = styled.main`
  padding: 8rem 0;
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  gap: 8rem;

  ${respond(
    "tab-land",
    css`
      gap: 4rem;
    `
  )}
  ${respond(
    "tab-port",
    css`
      grid-template-columns: 1fr;
      padding: 8rem 3rem;
    `
  )}
  ${respond(
    "phone-port",
    css`
      padding: 0 0 8rem 0;
      width: 90%;
      gap: 0;
    `
  )}

  h1 {
    font-size: 6rem;
    color: var(--color-primary);
  }

  p {
    ${respond(
      "tab-port",
      css`
        width: 80%;
      `
    )}
    ${respond(
      "phone-port",
      css`
        width: 100%;
      `
    )}
  }

  .image {
    ${respond(
      "tab-port",
      css`
        width: 70%;
        margin: 0 auto;
      `
    )}
  }
`;

const ContactsBody = ({ data: { text, image } }) => {
  return (
    <StyledContactsBody className="container">
      <div className="left-container">
        <h1>Contact</h1>
        <p>{text}</p>
        <ContactsForm />
      </div>
      <GatsbyImage className="image" image={getImage(image.localFile)} alt={image.alternativeText} />
    </StyledContactsBody>
  );
};

export default ContactsBody;
