import React from "react";

import Layout from "../layout/Layout";

import Seo from "../components/Seo";
import InnerHero from "../components/InnerHero";
import { graphql } from "gatsby";

import ContactsBody from "../sections/contact/ContactsBody";

const ContactPage = ({
  data: {
    strapiNicoleWebsite: { contactsPage },
  },
}) => {
  return (
    <main>
      <Seo title={"Nicole Tabs | Contacts"} description={""} language="en" />
      <Layout innerPage>
        <InnerHero />
        <ContactsBody data={contactsPage} />
      </Layout>
    </main>
  );
};

export const query = graphql`
  query ContactsQuery {
    strapiNicoleWebsite {
      contactsPage {
        text
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                transformOptions: { fit: COVER }
                width: 1200
                avifOptions: { quality: 90 }
              )
            }
          }
          alternativeText
        }
      }
    }
  }
`;

export default ContactPage;
